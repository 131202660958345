import HTMLReactParser from "html-react-parser";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import QuemSomosModel from "../../models/quem-somos";
import { GestorRequisicoes } from "../../services/gestor";
import "./sessao-sobre.scss";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";

class SessaoSobre extends Component {
  state: {
    configuracoes?: {
      quemSomosHome?: boolean;
      quemSomosPagina?: boolean;
    };
    dados?: QuemSomosModel;
  } = {};

  monstaImagensCarousel(
    imagens?: Array<{ showFirst?: boolean; src?: string }>
  ) {
    const retorno: Array<JSX.Element> = [];

    imagens?.forEach((imagem, index) => {
      retorno.push(
        <img
          key={index}
          className="carouselImagem"
          src={imagem.src}
          alt="imagem carousel"
        />
      );
    });

    return retorno;
  }

  componentDidMount() {
    const listaDeCaminhosUrl = window.location.pathname.split("/");

    GestorRequisicoes.getQuemSomos().then((dados) => {
      this.state.dados = dados;
      this.setState({ ...this.state });
    });

    if (listaDeCaminhosUrl[1] === "quem-somos") {
      this.state.configuracoes = {
        quemSomosPagina: true,
      };
      this.setState({ ...this.state });
      return;
    }

    this.state.configuracoes = {
      quemSomosHome: true,
    };
    this.setState({ ...this.state });
    return;
  }
  render(): React.ReactNode {
    //Configurando carosel
    const responsive = {
      desktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    let texto: string | null;

    try {
      texto = new DOMParser().parseFromString(
        this.state.dados?.text as string,
        "text/html"
      )!.body!.firstChild!.firstChild!.textContent;
    } catch {
      texto = "";
    }

    let imagemPrincipal: string | undefined;

    this.state.dados?.imagensArray?.forEach((imagem) => {
      if (imagem.showFirst) {
        imagemPrincipal = imagem?.src;
      }
    });

    return (
      <SkeletonTheme
        baseColor={COLORS.skeletonBaseColor}
        highlightColor={COLORS.skeletonHighlightColor}
      >
        <section id="sessaoSobre">
          {this.state?.configuracoes?.quemSomosHome ? (
            <Container>
              <Row>
                <Col lg={4} className="colunaImg">
                  {this.state.dados?.imagensArray?.at(0) ? (
                    <img
                      src={
                        imagemPrincipal
                          ? imagemPrincipal
                          : this.state.dados?.imagensArray?.at(0)?.src
                      }
                      alt="imagem-ultrapura"
                      className="imgSobre"
                    />
                  ) : (
                    <Skeleton width="100%" height="430px" />
                  )}
                </Col>
                <Col lg={8} className="colunaTexto">
                  <div>
                    <h2 className="titulo">
                      {this.state.dados?.title ? (
                        this.state.dados?.title
                      ) : (
                        <Skeleton count={1} />
                      )}
                    </h2>
                    {texto ? (
                      <p className="texto">{texto}</p>
                    ) : (
                      <Skeleton width="100%" count={10} />
                    )}
                    <Link title="quem somos" to="/quem-somos" className="botao">
                      Saiba mais
                    </Link>
                  </div>
                </Col>
              </Row>
              {/* <Row className="diretrizes">
                <Col lg={4}>
                  <p>
                    <b>Missão:</b> Trabalhar com amor e determinação para
                    prever, ajudar e atender as necessidades dos clientes.
                  </p>
                </Col>
                <Col lg={4}>
                  <p>
                    <b>Visão:</b> Ser uma empresa reconhecida pelo seu portfólio de alta qualidade com diversos produtos para estações de tratamento de água.
                  </p>
                </Col>
                <Col lg={4}>
                  <p>
                    <b>Valores:</b> Ética, Confiança e Respeito. Ser claro e
                    objetivo em nossas ações. Nosso relacionamento é
                    transparente com os colaboradores e clientes.
                  </p>
                </Col>
              </Row> */}
            </Container>
          ) : null}

          {this.state?.configuracoes?.quemSomosPagina ? (
            <Container id="QuemSomosPagina">
              <Row>
                <Col lg={6} className="carouselContainer">
                  <Row className="carouselRow">
                    {this.state.dados?.imagensArray?.at(0) ? (
                      <Carousel
                        responsive={responsive}
                        removeArrowOnDeviceType={["mobile", "tablet"]}
                        showDots={
                          (this.state.dados?.imagensArray?.length as number) > 1
                        }
                      >
                        {this.monstaImagensCarousel(
                          this.state.dados?.imagensArray
                        )}
                      </Carousel>
                    ) : (
                      <Skeleton width="100%" height="430px" />
                    )}
                  </Row>
                </Col>
                <Col lg={6} className="textoContainer">
                  <h2 className="titulo">
                    {this.state.dados?.title ? (
                      this.state.dados?.title
                    ) : (
                      <Skeleton width="100%" count={1} />
                    )}
                  </h2>
                  {this.state.dados?.text ? (
                    HTMLReactParser(this.state.dados?.text as string)
                  ) : (
                    <Skeleton width="100%" count={15} />
                  )}
                </Col>
              </Row>
            </Container>
          ) : null}
        </section>
      </SkeletonTheme>
    );
  }
}

export default SessaoSobre;
